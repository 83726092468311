import Link from "next/link";
import BiLogoWhite from "../public/img/bi.svg";
import { usePathname } from 'next/navigation';

const Footer: React.FC = ({}) => {
  const pathname = usePathname();
  const phString = pathname === '/contacto' ? 'PH: PC-MX-110046' : 'PH: PC-MX-110045';
  
  return (
    <footer className="w-full mt-auto footer-container gap-4 relative flex flex-col items-center py-10 text-sm text-gray-400 font-light my-5 leading-none">
      <div className="w-11/12">
        <a
          target="_blank"
          href="https://www.boehringer-ingelheim.mx/"
          rel="noopener"
        >
          <BiLogoWhite width="126" />
        </a>
      </div>
      <div className="w-11/12">
        <div className="italic flex flex-col gap-2 mb-4">
          <p>Boehringer Ingelheim Promeco S.A. de C.V.</p>
          <p>
            Maíz #49 Col. Barrio Xaltocan. C.P. 16090, Xochimilco, CDMX, México
          </p>
          <p>
            Material exclusivo para el profesional de la salud. Queda prohibida
            su divulgación, edición, copia o distribución. Cualquier uso no
            autorizado será responsabilidad del profesional de la salud o
            cualquier tercero que tenga acceso a la misma
          </p>
        </div>

        <p className="mb-4">{phString}</p>

        <p>
          Este sitio web es operado por las compañías de Boehringer Ingelheim en
          México y se sujeta a los Términos y Condiciones que se encuentran a su
          disposición en el sitio web.
        </p>
      </div>
      <div className="w-11/12">
        <p className="">
          Al acceder o utilizar este sitio web, usted acepta que ha leído,
          entendido y está de acuerdo en comprometerse con dichos Términos y
          Condiciones, así como con los términos de nuestro Aviso de Privacidad
          para Profesionales de la Salud, el cual podrá encontrar también en
          este sitio web. Si no está de acuerdo con los Términos y Condiciones,
          el Aviso de Privacidad para Profesionales de la Salud, o no está
          satisfecho con este Sitio Web, el único y exclusivo recurso que tiene
          es dejar de utilizarlo.
        </p>
      </div>
      <div className="w-11/12 flex flex-col lg:flex-row justify-between items-center">
        <div className="flex justify-start items-center w-full lg:w-3/6">
          <p>
            <a
              target="_blank"
              href="https://boehringerclick.mx/docs/aviso.pdf"
              className="uppercase cursor-pointer transition ease-in duration-100 hover:text-bi-green-malachite-500"
              rel="noopener"
            >
              Aviso de Privacidad
            </a>{" "}
            |{" "}
            <a
              target="_blank"
              href="https://boehringerclick.mx/docs/tc.pdf"
              className="cursor-pointer transition ease-in duration-100 hover:text-bi-green-malachite-500 uppercase"
              rel="noopener"
            >
              Términos de uso y condiciones
            </a>{" "}
            |{" "}
            <Link
              href="/contacto"
              className="cursor-pointer transition ease-in duration-100 hover:text-bi-green-malachite-500 uppercase"
            >
              Contacto
            </Link>
          </p>
        </div>
        {/* <div className="w-full lg:w-1/4 mt-5 lg:mt-0">
          <p className="text-xs text-gray-400 font-light text-center lg:text-right">
            &#169; 2021{" "}
            <span className="text-bi-green-malachite-500 cursor-pointer hover:underline">
              <a
                target="_blank"
                href="https://www.boehringer-ingelheim.mx/"
                rel="noopener"
              >
                {" "}
                Boehringer Ingelheim México
              </a>
            </span>{" "}
          </p>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
