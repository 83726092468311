import "../styles/index.css";
import "../styles/global.scss";
import { Provider } from "next-auth/client";
import { init } from "../src/api/sentry";
import Script from "next/script";
import { useEffect } from "react";

function MyApp({ Component, pageProps, err }) {
  useEffect(() => {
    init();
  }, []);

  return (
    <Provider session={pageProps.session}>
      <Component {...pageProps} err={err} />
      <Script
        strategy="afterInteractive"
        src={
          "https://script.bi-instatag.com?ref=" +
          encodeURIComponent("https://boehringerclick.mx")
        }
      />
    </Provider>
  );
}

export default MyApp;
